<template>
  <section class="pb-2" style="height: calc(100vh - 20px)">
    <form-wizard ref="addAutoWizard" color="#377DFF" :title="null" :subtitle="null" shape="square">
      <tab-content title="Dati auto">
        <b-row>
          <b-col md="4">
            <b-form-group label="Nazionalità">
              <v-select id="vehicle_nationality" v-model="vehicle_nationality"
                :options="nationalities" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Provenienza">
              <v-select id="vehicle_origin" v-model="vehicle_origin" :options="origins" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Data Ingresso">
              <b-form-input id="vehicle_entry_date" v-model="vehicle_entry_date" type="date" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Ditta">
              <v-select id="vehicle_business" v-model="vehicle_business" :options="businesses" label="nome_ditta" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Marca">
              <v-select id="vehicle_brand" v-model="vehicle_brand" :options="brand" label="nome_casamadre"
                @input="getCarModelByBrand" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Modello">
              <v-select id="vehicle_model" v-model="vehicle_model" :options="models" label="modello" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Versione">
              <b-form-input id="vehicle_version" v-model="vehicle_version"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Tipo">
              <v-select id="vehicle_type" v-model="vehicle_type" :options="types" label="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Status veicolo">
              <v-select id="vehicle_status" v-model="vehicle_status"
                :selectable="(option) => !option || !option.value.includes('select_value')" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Carrozzeria">
              <v-select id="vehicle_body" v-model="vehicle_body"
                :selectable="(option) => !option || !option.value.includes('select_value')" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Numero chiave">
              <b-form-input id="vehicle_key_number" v-model="vehicle_key_number"
                placeholder="Inserisci il numero di chiave" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Doppia chiave">
              <v-select id="vehicle_has_double_key" v-model="vehicle_has_double_key" :options="basic_options"
                label="text" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Data ricezione">
              <b-form-input type="date" v-model="vehicle_receive_date" placeholder="gg/mm/aa" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Numero passaggi">
              <b-form-input type="number" id="vehicle_passagge_no" v-model="vehicle_passagge_no"
                placeholder="Specifica il numero dei passaggi" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Anno d'immatricolazione">
              <v-select id="vehicle_matriculation_year" v-model="vehicle_matriculation_year"
                :selectable="(option) => !option || !option.value.includes('select_value')" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Mese d'immatricolazione">
              <v-select id="vehicle_matriculation_month" v-model="vehicle_matriculation_month"
                :selectable="(option) => !option || !option.value.includes('select_value')" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Chilometraggio">
              <b-form-input type="number" id="vehicle_kilometers" v-model="vehicle_kilometers"
                placeholder="Specifica il numero dei passaggi" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Ubicazione">
              <v-select id="vehicle_location" v-model="vehicle_location" :options="locations" label="nome_ubicazione" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Tipo veicolo">
              <v-select id="vehicle_type_2" v-model="vehicle_type_2" :options="types_2" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Cilindrata">
              <b-form-input type="number" id="vehicle_displacement" v-model="vehicle_displacement"
                placeholder="Specifica la cilindrata" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Potenza KW">
              <b-form-input type="number" id="vehicle_kw_power" v-model="vehicle_kw_power"
                placeholder="Specifica la potenza in KW" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Cavalli">
              <b-form-input type="number" id="vehicle_horsepower" v-model="vehicle_horsepower"
                placeholder="Specifica la potenza dei cavalli" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Alimentazione">
              <v-select id="vehicle_fuel" v-model="vehicle_fuel" :options="fuel_type" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Tipo di cambio">
              <v-select id="vehicle_shift" v-model="vehicle_shift" :options="shift_type" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Targa">
              <b-form-input id="vehicle_plate" v-model="vehicle_plate" placeholder="Specifica il numero di targa" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Telaio">
              <b-form-input id="vehicle_chassis" v-model="vehicle_chassis"
                placeholder="Specifica il numero di telaio" />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Colore">
        <p class="text-primary h4">Colori degli <b>esterni</b></p>

        <b-row>

          <b-col md="12" class="my-1">

            <b-form-radio-group>
              <b-row>
                <b-col md="2" class="mb-2" v-for="color in colors_ext" :key="color.value">
                  <b-form-radio class="custom" :value="color.value"
                    :style="{ '--bg-color': color.color, '--border-color': color.color }">
                    {{ color.text }}
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-radio-group>

            <div class="d-flex mt-3">

              <b-form-checkbox id="checkbox-1" class="custom-control-primary-light" switch name="checkbox-1"
                value="metallic">
                Vernice Metallizata
              </b-form-checkbox>

              <b-form-checkbox class="ml-3" id="checkbox-2" switch name="checkbox-2" :value="true"
                v-model="extra_color">
                Vernice Bi-Color
              </b-form-checkbox>

            </div>

          </b-col>


        </b-row>

        <div v-if="extra_color">

          <hr>

          <p class="text-primary h4">Colore extra degli <b>esterni</b></p>

          <b-row>
            <b-col md="12" class="my-2">
              <b-form-radio-group>
                <b-row>
                  <b-col md="2" class="mb-2" v-for="color in colors_ext" :key="color.value">
                    <b-form-radio class="custom" :value="color.value"
                      :style="{ '--bg-color': color.color, '--border-color': color.color }">
                      {{ color.text }}
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-form-radio-group>
            </b-col>
          </b-row>

        </div>

        <hr>

        <p class="text-primary h4">Colori degli <b>interni</b></p>

        <b-row>

          <b-col md="12" class="my-2">

            <b-form-radio-group>
              <b-row>
                <b-col md="2" class="mb-2" v-for="color in colors_int" :key="color.value">
                  <b-form-radio class="custom" :value="color.value"
                    :style="{ '--bg-color': color.color, '--border-color': color.color }">
                    {{ color.text }}
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-radio-group>


          </b-col>

        </b-row>

        <hr>

        <p class="text-primary h4">Materiali degli <b>interni</b></p>

        <b-row>

          <b-col md="12" class="my-2">

            <b-form-radio-group class="custom" :options="materials" />

          </b-col>

        </b-row>
      </tab-content>

      <tab-content title="Fornitore">
        <b-row>
          <b-col md="12">
            <b-form-group label="Fornitore">
              <v-select id="supplier" v-model="supplier" :options="suppliers"
                :selectable="(option) => !option || !option.value.includes('select_value')" label="text" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Numero documento">
              <b-form-input v-model="document_number" placeholder="Specifica il numero del documento" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Presa in carico">
              <b-form-input v-model="take_in_charge" placeholder="Specifica la presa in carico" />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Costi ripristino">
        <p class="text-primary h4">Costi di <b>ripristino</b></p>
        <b-row>
          <b-col md="6">
            <b-form-group label="Carrozzeria">
              <b-form-input placeholder="Specifica l'eventuale prezzo di ripristino" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Meccanica">
              <b-form-input placeholder="Specifica l'eventuale prezzo di ripristino" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Elettronica">
              <b-form-input placeholder="Specifica l'eventuale prezzo di ripristino" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Altro">
              <b-form-input placeholder="Specifica l'eventuale prezzo di ripristino" />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <p class="h4 text-primary">Costi dei <b>servizi</b></p>

        <b-row>
          <b-col md="4">
            <b-form-group label="Minivoltura">
              <b-form-input placeholder="Specifica l'eventuale prezzo del servizio" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Trasporto">
              <b-form-input placeholder="Specifica l'eventuale prezzo del servizio" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Altro">
              <b-form-input placeholder="Specifica l'eventuale prezzo del servizio" />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <p class="text-primary h4">Costi <b>totali</b></p>

        <b-row>
          <b-col md="6">
            <b-form-group label="Totale ripristino">
              <b-form-input placeholder="Totale per le spese di ripristino" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Totale servizi">
              <b-form-input placeholder="Totale per le spese di servizi" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Prezzo d'acquisto del veicolo">
              <b-form-input placeholder="Specifica il totale del prezzo d'acquisto" />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col md="12" class="d-flex justify-content-between">
            <p class="text-primary h4"><b>TOTALE COSTI:</b></p>
            <p class="text-primary h4"><b>00000.00</b></p>
          </b-col>
        </b-row>

        <hr />
      </tab-content>
      <tab-content title="Prezzi vendita">
        <p class="text-primary h4">Riepilogo totale dei costi</p>
        <b-row>
          <b-col md="6">
            <b-form-group label="Riepilogo costi di ripristino">
              <b-form-input placeholder="Riepilogo prezzo di ripristino" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Riepilogo costi dei servizi">
              <b-form-input placeholder="Riepilogo prezzo dei servizi" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Riepilogo prezzo d'acquisto">
              <b-form-input placeholder="Riepilogo prezzo d'acquisto" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Valore Eurotax">
              <b-form-input placeholder="Specifica il valore Eurotax" />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <p class="text-primary h4">Riepilogo <b>totale</b></p>

        <b-row>
          <b-col md="12" class="d-flex justify-content-between">
            <p class="text-primary h4"><b>TOTALE COSTI:</b></p>
            <p class="text-primary h4"><b>00000.00</b></p>
          </b-col>
        </b-row>

        <hr />

        <p class="text-primary h4">Prezzi <b>pubblici</b></p>

        <b-row>
          <b-col md="6">
            <b-form-group label="Prezzo di vendita al privato">
              <b-form-input placeholder="Specifica il prezzo di vendita per i privati" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Prezzo da mostrare sul sito e portali web">
              <b-form-input placeholder="Specifica il prezzo da mostrare sulle piattaforme" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Prezzo di vendita al rivenditore">
              <b-form-input placeholder="Specifica il prezzo per l'acquisto del venditore" />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col md="12" class="d-flex justify-content-between align-items-center">
            <div>
              <p class="h4">PREZZO VENDITA AL PUBBLICO:</p>
              <p class="text-success">
                Il prezzo indicato è un guadagno in positivo
              </p>
            </div>

            <p class="text-success h4"><b>00000.00</b></p>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" class="d-flex justify-content-between align-items-center">
            <div>
              <p class="h4">PREZZO VENDITA AL RIVENDITORE:</p>
              <p class="text-danger">
                Il prezzo indicato è un guadagno in perdita
              </p>
            </div>

            <p class="text-danger h4"><b>00000.00</b></p>
          </b-col>
        </b-row>

        <hr>
      </tab-content>

      <template #footer>
        <div class="fixed-bottom bg-white p-2">
          <div class="d-flex justify-content-between ml-0 ml-xl-20x">

            <div>
              <b-button variant="primary-light">Salva veicolo in bozza da pubblicare</b-button>
            </div>

            <div>
              <b-button v-if="$refs.addAutoWizard && $refs.addAutoWizard.activeTabIndex > 0" variant="outline-primary-light mr-2"
                @click="$refs.addAutoWizard && $refs.addAutoWizard.prevTab()">Indietro</b-button>
              <b-button v-if="!$refs.addAutoWizard || !$refs.addAutoWizard.isLastStep" variant="primary" @click="$refs.addAutoWizard.nextTab()">
                Prosegui
              </b-button>
              <b-button v-else variant="primary-light">Pubblica</b-button>
            </div>

          </div>
        </div>
      </template>
    </form-wizard>


  </section>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { BRow, BCol, BFormGroup, BFormInput, BFormRadio, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Requests } from '@/api';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
  },

  data: () => ({
    vehicle_origin: '',
    vehicle_nationality: '',
    document_number: '',
    vehicle_entry_date: '',
    vehicle_business: '',
    vehicle_receive_date: '',
    vehicle_passagge_no: '',
    vehicle_matriculation_year: '',
    vehicle_matriculation_month: '',
    vehicle_kilometers: '',
    vehicle_location: '',
    vehicle_type_2: '',
    vehicle_displacement: '',
    vehicle_kw_power: '',
    vehicle_horsepower: '',
    vehicle_fuel: '',
    vehicle_shift: '',
    vehicle_plate: '',
    vehicle_chassis: '',
    vehicle_model: '',
    vehicle_version: '',
    vehicle_type: '',
    vehicle_status: '',
    vehicle_body: '',
    vehicle_key_number: '',
    vehicle_has_double_key: '',
    businesses: [],
    brand: [],
    models: [],
    types: ['Usato', 'Nuovo', 'Km0'],
    types_2: ['Autovettura', 'Veic. commerciale'],
    fuel_type: [
      'Benzina',
      'GPL',
      'Diesel',
      'Metano',
      'Elettrica',
      'Benzina/GPL',
      'Benzina/Metano',
      'Elettrica/Benzina',
      'GPL/Metano'
    ],
    shift_type: ['Manuale', 'Automatico'],
    basic_options: ['Sì', 'No'],
    nationalities: [
      'Italia',
      'Estero'
    ],
    origins: [
      'Acquisto da Commercianti',
      'Permuta',
      'Conto Vendita',
      'Conto Esposizione',
      'Acquisto da privato non fatturabile',
      'Casa Madre',
      'Intermed. Concessionario',
      'Rottamazione'
    ],
    locations: [],

    vehicle_brand: null,

    extra_color: false,

    supplier: '',
    suppliers: [],
    take_in_charge: '',

    colors_ext: [
      { text: "Beige", value: "beige", color: "#EBCF98" },
      { text: "Blu/Azzurro", value: "blu/azzurro", color: "#679CFF" },
      { text: "Marrone", value: "marrone", color: "#983700" },
      { text: "Bronzo", value: "bronzo", color: "#FFA16C" },
      { text: "Giallo", value: "giallo", color: "#FFF500" },
      { text: "Grigio", value: "grigio", color: "#ABA8B4" },
      { text: "Verde", value: "verde", color: "#21B015" },
      { text: "Rosso", value: "rosso", color: "#E70000" },
      { text: "Nero", value: "nero", color: "#000000" },
      { text: "Argento", value: "argento", color: "#ABA8B4" },
      { text: "Lilla", value: "lilla", color: "#EC74FF" },
      { text: "Bianco", value: "bianco", color: "#FFFFFF" },
      { text: "Arancione", value: "arancione", color: "#FB5A00" },
      { text: "Oro", value: "oro", color: "#FFB74A" },
    ],

    colors_int: [
      { text: "Beige", value: "beige", color: "#EBCF98" },
      { text: "Blu/Azzurro", value: "blu/azzurro", color: "#679CFF" },
      { text: "Grigio", value: "grigio", color: "#ABA8B4" },
      { text: "Marrone", value: "marrone", color: "#983700" },
      { text: "Bianco", value: "bianco", color: "#FFFFFF" },
      { text: "Rosso", value: "rosso", color: "#E70000" },
    ],

    materials: [
      { text: "Alcantara", value: "alcantara" },
      { text: "Pelle parziale", value: "pelle parziale" },
      { text: "Pelle scamosciata", value: "pelle scamosciata" },
      { text: "Stoffa", value: "stoffa" },
      { text: "Altro", value: "altro" },
    ]
  }),

  created() {
    this.getBusiness();
    this.getCarBrand();
    this.getLocations();
    this.getSuppliersList();
  },

  methods: {

    async getBusiness() {
      try {
        const response = await Requests.getBusiness();
        this.businesses = response.data;
      } catch (err) {
        console.debug(err);
      }
    },

    async getCarBrand() {
      try {
        const response = await Requests.getCarBrand();
        this.brand = response;
      } catch (err) {
        console.debug(err);
      }
    },

    async getCarModelByBrand() {

      let data = new FormData();

      data.append('casamadre', this.vehicle_brand.nome_casamadre);

      try {
        const response = await Requests.getCarModelByBrand(data);
        this.models = response;
      } catch (err) {
        console.debug(err)
      }
    },

    async getLocations() {
      try {
        const response = await Requests.getLocations();
        this.locations = response.data;
      } catch (err) {
        console.debug(err);
      }
    },

    async getSuppliersList() {
      try {
        const response = await Requests.getSuppliersList();
        this.suppliers = response.data;
      } catch(err) {
        console.debug(err)
      }
    }

  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

